html {
  --mdc-dialog-z-index: 1055;

  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 500px;
  --mdc-dialog-container-color: white;

  --mat-dialog-headline-padding: 0 0 0.5rem 0;
  --mdc-dialog-subhead-color: var(--text);
  --mdc-dialog-subhead-font: var(--font_family_brand_regular);
  --mdc-dialog-subhead-line-height: 2.25rem;
  --mdc-dialog-subhead-size: var(--h2_font_size);
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0;

  --mat-dialog-content-padding: 0;
  --mat-dialog-with-actions-content-padding: 0;
  --mdc-dialog-supporting-text-color: var(--text);
  --mdc-dialog-supporting-text-font: var(--font-family-sans-serif);
  --mdc-dialog-supporting-text-line-height: var(--line_height);
  --mdc-dialog-supporting-text-size: var(--font_size_base);
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0em;

  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 0;

  .bgdark {
    --mdc-dialog-container-color: var(--dark_bg);
    --mdc-dialog-subhead-color: var(--dark_fg);
    --mdc-dialog-supporting-text-color: var(--dark_fg);
  }
}

.mdc-dialog {
  &__surface {
    padding: 2.5rem;
    border-radius: var(--border_radius);
    border: 1px solid var(--border_color);

    &::before {
      border: 0 !important;
    }

    .btn-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%233E53A4%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E);
      background-size: 1rem 1rem;
      color: var(--primary);
      opacity: 1;
      padding: 0.5rem;
      width: 1rem;
      height: 1rem;

      .bgdark & {
        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2217%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.218%201.782%201.075%2015.925m0-14.143%2014.143%2014.143%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
      }
    }
  }

  &__title {
    margin: 0 0 1.5rem 0 !important;
    border-bottom: 1px solid var(--border_color);

    &::before {
      display: none !important;
    }
  }

  &__content {
    padding: 0 1rem !important;
    margin: 0 -1rem !important;
  }

  &__actions {
    min-height: unset !important;
    margin-top: 1.5rem !important;

    .btn {
      min-width: 7rem;
    }
  }
}

// // Dialog
// $zindex-modal: 1050;
// $zindex-tooltip: 1070;

// .cdk-overlay-container {
//   z-index: $zindex-modal;

//   .mat-mdc-dialog-container {
//     padding: 24px;
//     border-radius: $border-radius;
//     color: var(--text);

//     // This is to always show tooltips/popovers above a modal
//     .tooltip,
//     .popover {
//       z-index: $zindex-tooltip + $zindex-modal;
//     }

//     .modal-header {
//       padding: 0;
//       margin-bottom: 8px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .mat-mdc-dialog-title {
//         margin: 0 0 8px;
//         font-family: var(--font_family_brand_regular);
//         font-weight: normal;
//       }

//       .close {
//         background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9 Im5vIj8+Cjxzdmcgd2lkdGg9IjE3cHgiIGhlaWdodD0iMTdweCIgdmlld0JveD0i MCAwIDE3IDE3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9y Zy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkv eGxpbmsiPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ry b2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVs ZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpv aW49InJvdW5kIj4KICAgICAgICA8ZyBpZD0iaWNvbi1iaXRzIiB0cmFuc2Zvcm09 InRyYW5zbGF0ZSgtMTYxLjAwMDAwMCwgLTE1OC4wMDAwMDApIiBzdHJva2U9IiMz RTUzQTQiIHN0cm9rZS13aWR0aD0iMiI+CiAgICAgICAgICAgIDxnIGlkPSJjbG9z ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTY5LjUwMDAwMCwgMTY2LjUwMDAwMCkg cm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTY5LjUwMDAwMCwgLTE2Ni41 MDAwMDApIHRyYW5zbGF0ZSgxNTkuMDAwMDAwLCAxNTYuMDAwMDAwKSI+CiAgICAg ICAgICAgICAgICA8cGF0aCBkPSJNMjAsMTAuNSBMMS4xMjI2NTc1MmUtMTIsMTAu NSIgaWQ9IkxpbmUiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0x MCwwLjUgTDEwLDIwLjUiIGlkPSJMaW5lIj48L3BhdGg+CiAgICAgICAgICAgIDwv Zz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg== ');
//         background-position: center center;
//         background-repeat: no-repeat;
//         background-size: 1rem 1rem;
//         color: var(--primary);
//         opacity: 1;
//         display: block;
//         margin: 0 0 8px;
//         transform: translateY(0);
//       }
//     }

//     .modal-body {
//       padding: 0;
//     }

//     mat-dialog-content,
//     .mat-mdc-dialog-content.mdc-dialog__content {
//       color: var(--text);
//     }

//     // material dialog footer buttons
//     mat-dialog-actions,
//     .mat-mdc-dialog-actions.mdc-dialog__actions {
//       padding: 1rem 0 0;
//       margin-bottom: 0;

//       button {
//         min-width: 7rem;

//         &.btn-primary:not([disabled]):not(.disabled),
//         &.btn-primary:not([disabled]):not(.disabled) {
//           background-color: var(--primary);
//           border-color: var(--primary);
//         }

//         // &.btn-secondary,
//         &.btn-secondary:not([disabled]),
//         &.btn-outline-secondary:not([disabled]),
//         &.btn-outline-primary:not([disabled]),
//         &.btn-link:not([disabled]),
//         &.btn-group .btn-secondary:not([disabled]),
//         &.btn-group .btn-outline-secondary:not([disabled]),
//         &.btn-group-vertical .btn-secondary:not([disabled]),
//         &.btn-group-vertical .btn-outline-secondary:not([disabled]),
//         &.btn-secondary:not([disabled]):not(.disabled),
//         &.btn-link:not([disabled]):not(.disabled),
//         &.btn-outline-secondary:not([disabled]):not(.disabled),
//         &.show > .btn-secondary.dropdown-toggle,
//         &.show > .btn-outline-secondary.dropdown-toggle {
//           color: var(--primary);
//           border-color: var(--primary);
//         }
//       }
//     }
//   }

//   .modal-backdrop {
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     background-color: var(--black);
//   }

//   // small size
//   .modal-dialog.modal-sm {
//     .modal-header {
//       margin-bottom: 0.5rem;

//       .close {
//         top: 0.5rem;
//         right: 0.5rem;
//         width: 0.5rem;
//         height: 0.5rem;
//       }
//     }

//     .modal-title {
//       font-size: 1rem;
//     }

//     .modal-content {
//       padding: 1rem;
//     }

//     .modal-footer {
//       margin-top: 0.5rem;

//       .btn {
//         min-width: 4rem;
//       }
//     }
//   }
// }
