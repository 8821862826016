.advanced-table-layout {
  --page-top-margin: 70px;
  --sidebar-width: 225px;

  grid-template-rows: 100%;
  grid-template-columns: var(--sidebar-width) calc(100% - var(--sidebar-width));

  @include media-breakpoint-down(lg) {
    --page-top-margin: 56px;
  }

  &-sidebar {
    height: calc(100vh - var(--page-top-margin) - var(--advanced-table-page-header-height, 0));
  }

  .advanced-table {
    max-height: calc(
      100vh - var(--page-top-margin) - var(--advanced-table-page-header-height, 0) - var(
          --advanced-table-toolbar-height,
          0
        )
    );
    min-height: calc(
      100vh - var(--page-top-margin) - var(--advanced-table-page-header-height, 0) - var(
          --advanced-table-toolbar-height,
          0
        )
    );
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  @at-root {
    body:has(.advanced-table-layout) {
      overflow-y: hidden;
    }
  }
}

.advanced-table {
  .selection-column {
    width: 4rem !important;
    min-width: 4rem !important;
    max-width: 4rem !important;
  }

  .integration-column {
    .icon {
      display: inline-block;
      min-width: 44px;
      width: 44px;
      max-width: 44px;
      height: 32px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .tags-column {
    .tag-wrapper {
      height: 20px;
    }

    .tag {
      width: 10px;
      height: 100%;
    }
  }

  .items-column {
    .item-icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-origin: content-box;
    }

    .item-icon-sizer {
      width: 1px;
      background: unset;
    }

    @at-root {
      .item-popover {
        min-width: 450px;
        font-size: 0.875rem;

        .popover-body {
          padding: 0;
        }

        .item-wrapper {
          max-height: 300px;
          height: 300px;

          .item {
            height: 80px;
          }

          .item-icon {
            display: inline-block;
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            background-origin: content-box;
          }

          .item-icon-popover {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }

  .carrier-column {
    .icon {
      display: inline-block;
      min-width: 44px;
      width: 44px;
      max-width: 44px;
      height: 32px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .service-column {
    .icon {
      display: inline-block;
      min-width: 44px;
      width: 44px;
      max-width: 44px;
      height: 32px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .extra-services-column {
    @at-root {
      .extra-service-popover {
        min-width: 250px;
        font-size: 0.875rem;

        .popover-body {
          padding: 0;
        }

        .extra-service-wrapper {
          max-height: 300px;
          overflow-y: auto;
        }
      }
    }
  }

  .gift-column {
    .icon {
      display: inline-block;
      min-width: 16px;
      width: 16px;
      max-width: 16px;
      height: 22px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .action-column {
    width: 4rem !important;
    min-width: 4rem !important;
    max-width: 4rem !important;
  }

  .action-menu {
    @at-root {
      .cost-breakdown {
        max-width: 400px !important;
        min-width: 400px !important;
        padding: 0.5rem 0.75rem;
      }

      .table-reduced-padding td {
        padding: 0.25rem 0 !important;
      }
    }
  }
}

.advanced-table-sidenav {
  --mat-sidenav-container-width: 600px;
  --mat-sidenav-container-background-color: var(--rich100);

  --sidenav-resize-panel-minwidth: 500px;
  --sidenav-resize-panel-maxwidth: 50vw;

  --page-top-margin: 70px;

  @include media-breakpoint-down(lg) {
    --page-top-margin: 56px;
  }

  margin-top: var(--page-top-margin);
  min-width: var(--sidenav-resize-panel-minwidth);
  max-width: var(--sidenav-resize-panel-maxwidth);

  .sidenav-resize-handle {
    width: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    &:hover,
    &-resizing {
      cursor: ew-resize;
      background-color: var(--focus_border_color);
    }
  }
}
