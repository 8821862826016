html {
  // --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  // --mat-sidenav-container-background-color: white;
  // --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: transparent;
  --mat-sidenav-content-text-color: unset;
  // --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);

  // --mat-sidenav-container-shape: 0;
  // --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  // --mat-sidenav-container-width: auto;
}
