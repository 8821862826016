.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  user-select: none;
  border: 1px solid var(--input_border_color);
  background-color: #fff;
  border-radius: var(--border_radius);

  .bgwhite & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
    border: 1px solid var(--dark_input_border);
  }
}

.p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  // .pi {
  //   margin-top: 0.3em;
  //   margin-left: -0.125em;
  //   font-family: 'pbi-icon-mini';

  //   &.pi-caret-down::before {
  //     content: '\ea33';
  //   }
  // }
}

.p-inputwrapper {
  .p-dropdown-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    border: 0;

    &:focus-visible {
      outline: none;
    }
  }

  .p-dropdown-label-empty {
    overflow: hidden;
    visibility: hidden;
  }
}

.p-dropdown-panel {
  position: absolute;
  min-width: 100%;
  background-color: #fff;
  border: 1px solid var(--input_border_color);

  .p-dropdown-header {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid var(--border_color);

    .p-dropdown-filter-container {
      display: grid;

      .p-dropdown-filter {
        grid-row: 1;
        grid-column: 1;
        width: auto;
        padding: 0.25rem 1.75rem 0.25rem 0.25rem;

        &:focus-visible {
          @include focused;
        }
      }

      searchicon {
        grid-row: 1;
        grid-column: 1;
        align-self: center;
        justify-self: end;
        margin-right: 0.5rem;
        line-height: 0;

        .p-dropdown-filter-icon {
          position: relative;
          top: unset;
          margin-top: unset;
        }
      }
    }
  }
}

.p-dropdown-items-wrapper {
  overflow-y: auto;

  &:focus-visible {
    @include focused;
  }

  .p-dropdown-empty-message {
    padding: 0.5rem 0.5rem;
  }
}

.p-dropdown-item {
  white-space: nowrap;
  margin: 0;
  padding: 0.5rem 0.5rem;

  &.p-focus,
  &:hover {
    background-color: var(--rich200);
  }
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-fluid .p-dropdown .p-dropdown-label {
  width: 1%;
}

.p-treeselect {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--input_border_color);
  padding: 0.4rem 0.75rem;

  .bgdark & {
    border: 1px solid var(--dark_input_border);
    background: var(--dark_input_bg);
  }
  .bgwhite & {
    background-color: var(--form_control_bg_richcolor);
  }
  &.p-focus {
    @include focused;
  }
}

.p-treeselect .p-tree-wrapper {
  border: 1px solid var(--input_border_color);
  background-color: #fff;
  box-shadow: 0 1px #0000000f;

  .bgdark & {
    border: 1px solid var(--dark_input_border);
    background: var(--dark_input_bg);
  }
}

.p-treeselect .p-tree-wrapper .p-treenode-content.p-treenode-selectable {
  .p-tree-wrapper {
    border: 1px solid var(--input_border_color);
    background-color: #fff;
    box-shadow: 0 1px #0000000f;

    .bgdark & {
      border: 1px solid var(--dark_input_border);
      background: var(--dark_input_bg);
    }
  }
}

.p-tree-wrapper .p-treenode-content.p-treenode-selectable {
  padding: 0.5rem 1rem;
}

.p-treeselect .p-tree-wrapper .p-treenode-content.p-treenode-selectable {
  &:hover {
    background-color: var(--menu_hover);
    color: var(--text);
    outline: 1.5px solid var(--focus_border_color);
    outline-offset: -1px;
    .bgdark & {
      background: var(--dark_menu_hover);
      color: #fff;
    }
  }
  &:focus-visible {
    outline: 1.5px solid var(--focus_border_color);
    outline-offset: -1px;
  }
}

.p-tree-wrapper .p-tree-toggler-icon {
  color: var(--text);
  margin-right: 4px;
}

.p-treeselect .p-tree-wrapper .p-treenode {
  margin-bottom: 0;
}
.p-treeselect .p-tree-wrapper .p-treenode:focus-visible {
  @include focused;
  outline-offset: -1px;
}
.p-tree-wrapper .p-treenode {
  margin-bottom: 0;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  background: var(--rich200);
  color: var(--text);
  font-size: var(--font_size_sm);
  border-radius: 16px;

  .bgdark & {
    background-color: var(--dark_token_color);
    color: #000;
  }
}

.p-treeselect .p-treenode-selectable.p-highlight {
  background-color: #fff;

  .bgdark & {
    background-color: var(--dark_menu-hover);
  }
}

.p-treeselect .p-treenode-icon {
  display: none;
}

.p-treeselect .p-treenode-label {
  padding-left: 0;
}
