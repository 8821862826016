.action-list-panel {
  .mat-sidenav-container {
    --mat-sidenav-content-background-color: var(--rich100);

    .mat-sidenav {
      margin-top: 70px;
      width: 400px;

      .mat-drawer-inner-container {
        padding: 2rem;
        height: unset;
      }
    }
  }

  .action-panel-grid {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    height: 100vh;
    gap: 1rem;
  }
}
