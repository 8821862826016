// all select menus with form-control class, except multi-select
select.form-control:not([size]):not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  @include menu-droparrow;

  &[disabled],
  &.disabled {
    @include disabled;

    & {
      @include menu-droparrow-disabled;
    }
  }
}

// multi-select menus
select.form-control[size],
select.form-control[multiple] {
  background-image: none !important;
  color: var(--text);
  font-size: var(--font_size_base);
  height: auto;
}

.bgdark select.form-control:not([size]):not([multiple]):disabled {
  background-color: #2a3038;
  color: var(--dark_input_disabled_bg);
  -webkit-text-fill-color: var(--dark_input_disabled_bg);
  border-color: var(--dark_input_border);
}

.bgdark select.form-control[multiple]:disabled,
.bgdark select.form-control[size]:disabled {
  background-color: #2a3038;
  color: var(--dark_input_disabled_text);
  -webkit-text-fill-color: var(--dark_input_disabled_text);
  border-color: var(--dark_input_border);

  option {
    color: var(--dark_input_disabled_text);
  }
}

.ui-select-bootstrap .ui-select-choices-row.active > a {
  background-color: var(--token_color);
  color: var(--text);
  // outline: 0;
  text-decoration: none;
}

// date and time picker
.form-control[disabled].datepicker,
.form-control[readonly].datepicker,
fieldset[disabled] .form-control.datepicker,
.form-control[disabled].timepicker,
.form-control[readonly].timepicker,
fieldset[disabled] .form-control.timepicker {
  background-color: var(--white);
  cursor: pointer;
}

div[ngbdropdownmenu].dropdown-menu {
  margin-top: 0;
}

.ui-multiselect-trigger-icon {
  color: var(--btn_primary);
}

// this rule is overriden for pmg menus inside a toolbar in toolbar.scss, top 3px
.ui-multiselect .ui-multiselect-trigger,
.ui-multiselect .ui-dropdown-trigger,
.ui-dropdown .ui-multiselect-trigger,
.ui-dropdown .ui-dropdown-trigger,
.p-multiselect .p-multiselect-trigger,
.p-multiselect .p-dropdown-trigger,
.p-dropdown .p-multiselect-trigger,
.p-dropdown .p-dropdown-trigger {
  top: 6px !important;
}

// dropdown buttons
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  border-top: 0.45em solid;
  border-right: 0.45em solid transparent;
  border-bottom: 0;
  border-left: 0.45em solid transparent;
  content: '';
  vertical-align: 0.2em;
  color: inherit;
}

// dropup buttons
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  border-top: 0;
  border-right: 0.4em solid transparent;
  border-bottom: 0.5em solid;
  border-left: 0.4em solid transparent;
  content: '';
  vertical-align: 0.1em;
}

.dropdown-header {
  padding: 0.5rem 1.25rem;
  color: var(--text);
  font-size: 0.875rem;
}

// ng bootstrap dropdowns in a form group (i.e. with a label)

.dropdown:not(.nav-item) {
  .dropdown-menu {
    // width: 100%;
    border: 1px solid var(--input_border_color);
  }

  .dropdown-toggle {
    // width: 100%;
    background-color: var(--white);
    // @include menu-droparrow;
    text-align: left;
    // font-family: var(--font_family_base);
    // font-size: var(--font_size_base);
    color: var(--text);
    border: 1px solid var(--input_border_color);
    border-radius: 2px;
    // padding-right: 22px;

    &.dropdown-toggle-hidden {
      &::after {
        display: none;
      }
    }

    &.form-select {
      &::after {
        border: 0;
      }
    }

    .bgwhite &,
    .bg-white & {
      &.form-select {
        background-color: var(--rich100);
      }
    }

    .bgdark & {
      background-color: var(--dark_input_bg);
    }

    &:focus {
      @include focused;
    }
  }
}

.dropdown-menu {
  // width: 100%;
  border: 1px solid var(--input_border_color);
  font-size: var(--font_size_base);
  // box-shadow: none;
  background-clip: border-box;

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: var(--dark_fg);
  }
}

.form-select {
  background-size: auto !important;
  font-size: var(--font_size_base);
  color: var(--text);
  border: 1px solid var(--input_border_color);
  border-radius: 2px;
  padding-right: 22px !important;
  padding: 0.5rem 2.25rem 0.5rem 0.75rem;

  @include menu-droparrow;

  .bgwhite &,
  .bg-white & {
    background-color: var(--rich100);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
  }

  &:focus,
  &.focus {
    box-shadow: none;
    border-color: var(--input_border_color);
    @include focused;
  }

  &[multiple],
  &[size]:not([size='1']) {
    background-image: none !important;
  }
  &.is-invalid:focus,
  &.was-validated .form-select:invalid:focus {
    border-color: var(--danger) !important;
    box-shadow: none !important;
  }
}

// override bootstrap's "reboot"
a.dropdown-item {
  color: var(--text) !important;
}

.bgdark a.dropdown-item {
  color: var(--dark_fg) !important;
}

// fix missing invalid styles on ng-select, JIRA 4730
ng-select,
.ng-select {
  &:invalid,
  &.is-invalid {
    border-color: var(--danger);

    .ng-select-container,
    .ng-dropdown-panel {
      border-color: var(--danger);
    }

    ~ .invalid-feedback {
      display: block;
    }
  }
  //  &.ng-select-focused{
  //     @include focused;
  //   }
}

// fix for header menus: focus state should show only when using keyboard
.dropdown-item {
  &:focus {
    background-color: transparent;
  }

  &:focus:hover {
    background-color: var(--rich100);
  }

  &:focus-visible {
    // background-color: var(--rich100);
  }
}
